import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import { doc, getDoc } from "firebase/firestore";
import { signOut } from "@firebase/auth";
import { auth, db } from "@/firebase/config";

const actions: ActionTree<any, any> = {
  fetchProfileDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const userDocRef = doc(db, "users", data);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          commit("SET_LOGGED_IN", true);
          commit("saveUser", docSnap.data());
          const data = docSnap.data();
          const expiry = data.expiry.seconds;
          const now = new Date();
          const secondsSinceEpoch = Math.round(now.getTime() / 1000);

          if (expiry < secondsSinceEpoch) {
            await signOut(auth);
            reject("Account Expired, Please reach the admin to renew your account");
          } else {
            commit("SET_USER_DATA");
            resolve(docSnap.data());
          }
        } else {
          await signOut(auth);
          reject("Invalid User, Please contact admin");
        }
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  updateProfilePassword({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/set_or_reset_password/", formData);

        dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const profileStore: Module<any, any> = {
  state: () => ({}),
  mutations: {
    saveUser(state, user) {
      localStorage.setItem("user", JSON.stringify(user));
    },
  },
  actions,
  getters: {},
};

export default profileStore;
