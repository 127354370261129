const fetchCompaniesList = `<ENVELOPE>
<HEADER>
<VERSION>1</VERSION>
<TALLYREQUEST>Export</TALLYREQUEST>
<TYPE>Collection</TYPE>
<ID>List of Companies</ID>
</HEADER>
<BODY>
<DESC>
<STATICVARIABLES>
            <SVIsSimpleCompany>No</SVIsSimpleCompany>
            </STATICVARIABLES>
<TDL>
<TDLMESSAGE>
<COLLECTION ISMODIFY="No" ISFIXED="No" ISINITIALIZE="Yes" ISOPTION="No" ISINTERNAL="No" NAME="List of Companies">
<TYPE>Company</TYPE>
<NATIVEMETHOD>Name</NATIVEMETHOD>
</COLLECTION>
                    <ExportHeader>EmpId:5989</ExportHeader>
</TDLMESSAGE>
</TDL>
</DESC>
</BODY>
</ENVELOPE>`;

const fetchLedgerList = `<ENVELOPE>
    <HEADER>
        <VERSION>1</VERSION>
        <TALLYREQUEST>EXPORT</TALLYREQUEST>
        <TYPE>COLLECTION</TYPE>
        <ID>List of Ledgers</ID>
    </HEADER>
    <BODY>
        <DESC>
            <STATICVARIABLES>
                <SVEXPORTFORMAT>$$SysName:XML</SVEXPORTFORMAT>
            </STATICVARIABLES>
        </DESC>
    </BODY>
</ENVELOPE>`;

export const tallyData = {
  fetchCompaniesList,
  fetchLedgerList,
};
