import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "App",
    component: () => import(/* webpackChunkName: "base" */ "../layouts/Main.vue"),
    children: [
      {
        path: "/",
        name: "checking",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "home" */ "../views/CheckingPage.vue"),
      },
      {
        path: "/home",
        name: "home",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "home" */ "../views/home/HomePage.vue"),
      },
      {
        path: "/sales-without-inventory",
        name: "sales-without-inventory",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/sales/SalesWithOutInventory.vue"),
      },
      {
        path: "/sales-with-inventory",
        name: "sales-with-inventory",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/sales/SalesWithInventory.vue"),
      },
      {
        path: "/purchase-without-inventory",
        name: "purchase-without-inventory",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/purchases/PurchasesWithOutInventory.vue"),
      },
      {
        path: "/purchase-with-inventory",
        name: "purchase-with-inventory",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/purchases/PurchasesWithInventory.vue"),
      },
      {
        path: "/purchase-without-inventory-gstr-2b",
        name: "purchase-without-inventory-gstr-2b",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/purchases/PurchasesWithOutInventoryGSTR2B.vue"
          ),
      },
      {
        path: "/banks-data",
        name: "banks-data",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "home" */ "../views/banks/BankStatements.vue"),
      },
      {
        path: "/banks-data-format-2",
        name: "banks-data-format-2",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/banks/BankStatementsFormat2.vue"),
      },
      {
        path: "/banks-data-format-3",
        name: "banks-data-format-3",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/banks/BankStatementsFormat3.vue"),
      },
      {
        path: "/logout",
        name: "LogoutPage",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "home" */ "../views/auth/LogoutPage.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "NoAuth",
    component: () => import(/* webpackChunkName: "base" */ "../layouts/MainNoAuth.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/LoginPage.vue"),
      },
    ],
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isLoggedIn) next({ name: "login" });
  else if (to.meta.isLoginPage && store.state.isLoggedIn) next({ name: "home" });
  else next();
});

export default router;
