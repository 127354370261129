import { createStore } from "vuex";
import router from "@/router";
import authStore from "./auth/authStore";
import profileStore from "./profile/profileStore";
import tallyStore from "@/store/tallyStore";
import { signOut } from "@firebase/auth";
import { auth } from "@/firebase/config";

export default createStore({
  state: {
    isLoggedIn: false,
    isLoading: false,
    activeUser: {},
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_USER_DATA(state) {
      try {
        const user = localStorage.getItem("user");
        state.activeUser = JSON.parse(user || "{}");
      } catch (e) {
        state.activeUser = {};
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isLoading(state) {
      return state.isLoading;
    },
    activeUser(state) {
      return state.activeUser;
    },
  },
  actions: {
    async checkLoginStatus({ commit, dispatch }) {
      const user = localStorage.getItem("user");
      if (user) {
        commit("SET_LOGGED_IN", true);
        // dispatch("fetchProfileDetails")
        commit("SET_USER_DATA");
      } else {
        commit("SET_LOGGED_IN", false);
        commit("SET_USER_DATA");
      }
    },
    async logout({ commit }) {
      signOut(auth)
        .then(async () => {
          console.log("Sign-out successful.");
          localStorage.clear();
          commit("SET_LOGGED_IN", false);
          commit("SET_USER_DATA");
          await router.push("/login");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  modules: {
    authStore,
    profileStore,
    tallyStore,
  },
});
