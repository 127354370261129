import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import { tallyData } from "@/utils/tallyStoreData";

import { XMLParser } from "fast-xml-parser";

const parser = new XMLParser();

const tallyBaseUrl = "http://localhost:2222";

const actions: ActionTree<any, any> = {
  setCompany({ commit }, company) {
    commit("SET_SELECTED_COMPANY", company);
  },
  fetchCompaniesList({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(tallyBaseUrl, tallyData.fetchCompaniesList);

        const jsonData = parser.parse(response.data);
        try {
          const companies = jsonData.ENVELOPE.BODY.DATA.COLLECTION.COMPANY;
          commit("SET_COMPANIES", companies);
        } catch (e) {
          console.error(e);
        }
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  fetchLedgersList({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(tallyBaseUrl, tallyData.fetchLedgerList, {
          headers: {
            "Content-Type": "text/plan",
          },
        });

        const jsonData = parser.parse(response.data);
        try {
          const listLedgers: any = [];
          const listData = jsonData.ENVELOPE.BODY.DATA.COLLECTION.LEDGER;
          listData.forEach((ledger: any) => {
            listLedgers.push(ledger["LANGUAGENAME.LIST"]["NAME.LIST"]["NAME"]);
          });
          commit("SET_LEDGERS", listLedgers);
        } catch (e) {
          console.error(e);
        }
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  importDataToTally({ commit, dispatch }, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(tallyBaseUrl, data, {
          headers: {
            "Content-Type": "text/plan",
          },
        });

        let jsonData = parser.parse(response.data);
        try {
          jsonData = jsonData["RESPONSE"];
          if (jsonData["LINEERROR"] != undefined) {
            alert(jsonData["LINEERROR"] || "Failed to import data");
            reject(jsonData["LINEERROR"]);
          } else {
            if (jsonData["ERRORS"] === 0) {
              resolve(response.data);
            } else if (!jsonData["CREATED"]) {
              if (typeof jsonData == "string") {
                alert(jsonData);
                reject(jsonData);
              } else {
                alert("Failed to import data");
                reject("Failed to import data");
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
};
const tallyStore: Module<any, any> = {
  state: () => ({
    companies: [],
    ledgers: [],
    selectedCompany: "",
  }),
  mutations: {
    SET_COMPANIES(state, companies) {
      if (!Array.isArray(companies)) {
        state.companies = [companies];
      } else {
        state.companies = companies;
      }
    },
    SET_SELECTED_COMPANY(state, company) {
      state.selectedCompany = company;
    },
    SET_LEDGERS(state, ledgers) {
      if (!Array.isArray(ledgers)) {
        state.ledgers = [ledgers];
      } else {
        state.ledgers = ledgers;
      }
    },
  },
  actions,
  getters: {
    companies: (state) => state.companies,
    selectedCompany: (state) => state.selectedCompany,
    ledgers: (state) => state.ledgers,
  },
};

export default tallyStore;
