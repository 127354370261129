import axios from "@/http/auth";
import { Module, ActionTree } from "vuex";
import { auth } from "@/firebase/config";
import { signInWithEmailAndPassword, signOut } from "@firebase/auth";

const actions: ActionTree<any, any> = {
  async performLogin({ commit, dispatch }, { email, password }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await signInWithEmailAndPassword(auth, email, password);

        commit("SET_LOADING", false);
        if (response) {
          dispatch("fetchProfileDetails", response.user.uid)
            .then((res: any) => {
              resolve(response.user);
            })
            .catch((err: any) => {
              reject(err);
            });
          resolve(response);
        } else {
          reject("Login Failed");
        }
      } catch (err) {
        commit("SET_LOADING", false);
        reject("Invalid Email/Password");
      }
    });
  },
};
const authStore: Module<any, any> = {
  state: () => ({}),
  mutations: {},
  actions,
  getters: {},
};

export default authStore;
