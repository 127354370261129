//Import the required methods
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

//The config we copied from firebase(Replace with your config)
const firebaseConfig = {
  apiKey: "AIzaSyBa-rvA8o8NFpQXDYjb4zX1VJnQBjNmfRM",
  authDomain: "tallyimport-karthik.firebaseapp.com",
  projectId: "tallyimport-karthik",
  storageBucket: "tallyimport-karthik.appspot.com",
  messagingSenderId: "945169104689",
  appId: "1:945169104689:web:a658ddd250dbe85a603a10",
};

//initialize the firebase app
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth();
const db = getFirestore(app);

//export the auth object
export { auth, db };
